<template>
  <div class="PageBox">
    <NavBar :title="$t('fundRecord.default[2]')" :page-number="-1"/>
    <div class="ScrollBox">
      <div class="tool Panel">
        <div style="width: 50%">
          <div>{{ $t("hall.default[1]") }}</div>
          <div style="font-size: 22px">{{ Number(UserInfo.balance).toFixed(2) }}</div>
          <div style="color: var(--font_subtitle)">USDT</div>
        </div>
        <div>
          <div>≈</div>
          <div style="font-size: 22px">{{Number(UserInfo.balance*InitData.usdtinfo.price).toFixed(2) }}</div>
          <div style="color: var(--font_subtitle)">{{InitData.usdtinfo.sign}}</div>
        </div>
      </div>
      <van-tabs  title-active-color="var(--tabs-title-color)" color="var(--tab-line-color)"
                 title-inactive-color="var(--tabs-title-inactive-color)" :ellipsis="false"
                :border="false" v-model="tabsIndex" @change="changeTabs" :line-width="108">
        <van-tab style="padding: 0 0px;width: 135px;margin-left: -25px">
          <template #title>
            <div>
              <p style="font-size: 16px">{{$t('home4[3]')}}</p>
            </div>
          </template>
        </van-tab>
        <van-tab style="padding: 0 0px;">
          <template #title>
            <div>
              <p style="font-size: 16px">{{$t('wallet.default[1]')}}</p>
            </div>
          </template>
        </van-tab>
        <van-tab style="padding: 0 0px;">
          <template #title>
            <div>
              <p style="font-size: 16px">{{$t('wallet.default[2]')}}</p>
            </div>
          </template>
        </van-tab>
      </van-tabs>
      <div class="Panel">
      <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <van-list
          v-model="isLoad"
          :finished="isFinished"
          :finished-text="
            listData[tabsIndex].length ? $t('vanPull[0]') : $t('vanPull[1]')
          "
          @load="onLoad"
          :class="{ Empty: !listData[tabsIndex].length }"
        >
          <div v-if="tabsIndex == 0">
            <van-cell
              class="FundItem"
              v-for="(item, index) in listData[tabsIndex]"
              :key="item.dan"
            >
              <div :class="`icon tag${tabsIndex}`" slot="icon"></div>
              <template #title>
                <div>
                  <span class="dan" style="color: var(--font_subtitle)">
                    {{item.order_number}}
                  </span>
                  <span class="dan" style="font-weight: bold;font-size: 20px;color: var(--font_subtitle)">
                    {{ item.amount }} USDT
                  </span>
                </div>
                <div>
                  <span style="color: #ffffff;opacity:0.6;">{{ item.adddate }}</span>
                  <span style="color: #ec4063">{{ item.statestr}}</span>
                </div>
              </template>
            </van-cell>
          </div>
          <div v-else>
            <van-cell
              class="FundItem"
              v-for="(item, index) in listData[tabsIndex]"
              :key="item.order_id"
            >
              <div :class="`icon tag${tabsIndex}`" slot="icon"></div>
              <template #title>
                <div>
                  <span class="dan" style="color: var(--font_subtitle);">{{ item.dan?item.dan:item.order_number }}</span>
                  <span style="font-weight: bold;font-size: 18px;color: var(--bc-text-color-0);" v-if="tabsIndex==1">{{ item.jj }}{{ parseFloat(item.money).toFixed(2) }} USDT</span>
                  <span style="font-weight: bold;font-size: 18px;color: #ec4063;" v-if="tabsIndex==2">{{ item.jj }}{{ parseFloat(item.money).toFixed(2)  }} {{InitData.usdtinfo.sign}}</span>
                </div>
                <div>
                  <span style="color: #ffffff;opacity:0.6;">{{ item.adddate }}</span>
                  <span style="color: var(--bc-text-color-0);" v-if="tabsIndex==1">{{ item.status_desc}}</span>
                  <span style="color: #ed8a2f;" v-if="tabsIndex==2&&item.status==1">{{$t('task.msg[3]')}}</span>
                  <span style="color: #ed8a2f;" v-if="tabsIndex==2&&item.status==2">{{$t('login.codes[3]')}}</span>
                  <span style="color: #ed8a2f;" v-if="tabsIndex==2&&item.status==3">{{$t('task.tabs[1]')}}</span>
                </div>
              </template>
            </van-cell>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FundRecord",
  components: {},
  data() {
    return {
      listData: "",
      isLoad: false,
      isFinished: false,
      isRefresh: false,
      pageNo: 1,
      tabsIndex: 0,
      taskTabs: [
        { state: 0, text: this.$t("common7[4]") },
        { state: 1, text: this.$t("fundRecord.tabs[0]") },
        { state: 2, text: this.$t("fundRecord.tabs[1]") },
      ],
    };
  },
  computed: {},
  created() {

    this.listData = this.taskTabs.map((item) => "");
    this.$parent.navBarTitle = "";

    this.getListData("init");
    console.log(this.listData);
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    onLoad() {
      this.getListData("load");
    },
    changeTabs(index) {
      this.tabsIndex = index;
      this.getListData("init");
    },
    getListData(type) {
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }
      //买卖
      if (this.tabsIndex == 0) {
        let param = {
          status: 3,
          page_no: this.pageNo,
          is_u: 2
        }
        this.$Model.GetTaskRecord(param,data => {
          this.isLoad = false;
          if (data.code == 1) {
            var items = data.info;
            items.forEach(item=>{
              var task_type = item['task_type']
              var ordertype = item['ordertype']
              var typestr = this.$t('hall4[6]')
              if (task_type==2||ordertype==2)
                typestr = this.$t('hall4[7]')
              item['task_typestr'] = typestr;
              var pay_state = item['pay_state']
              var statestr = this.$t('task3[1]')
              if (pay_state==2){
                statestr = this.$t('task3[2]')
              }
              item['statestr'] = statestr;
            })
            if (type == "load") {
              this.listData[this.tabsIndex] = this.listData[
                      this.tabsIndex
                      ].concat(items);
            } else {
              this.listData[this.tabsIndex] = items;
            }
            if (this.pageNo == data.data_total_page) {
              this.isFinished = true;
            } else {
              this.isFinished = false;
            }
          } else {
            this.listData[this.tabsIndex] = "";
            this.isFinished = true;
          }
          }
        );
      }else if (this.tabsIndex == 1) {
        this.$Model.GetRechargeRecord(
                { task_type: 2,is_u:1, page_no: this.pageNo },
                (data) => {
                  this.isLoad = false;
                  if (data.code == 1) {
                    if (type == "load") {
                      this.listData[this.tabsIndex] = this.listData[
                              this.tabsIndex
                              ].concat(data.info);
                    } else {
                      this.listData[this.tabsIndex] = data.info;
                    }
                    if (this.pageNo == data.data_total_page) {
                      this.isFinished = true;
                    } else {
                      this.isFinished = false;
                    }
                  } else {
                    this.listData[this.tabsIndex] = "";
                    this.isFinished = true;
                  }
                }
        );
      } else{
        this.$Model.GetDrawRecord(
          { page_no: this.pageNo },
          (data) => {
            this.isLoad = false;
            if (data.code == 1) {
              if (type == "load") {
                this.listData[this.tabsIndex] = this.listData[
                  this.tabsIndex
                ].concat(data.info);
              } else {
                this.listData[this.tabsIndex] = data.info;
              }
              if (this.pageNo == data.data_total_page) {
                this.isFinished = true;
              } else {
                this.isFinished = false;
              }
            } else {
              this.listData[this.tabsIndex] = "";
              this.isFinished = true;
            }
          }
        );
      }
    },
    onRefresh() {
      this.getListData("init");
    },
  },
};
</script>

<style scoped>
.PageBox >>> .van-nav-bar__title{
  font-size: 18px;
}
.PageBox >>> .van-nav-bar .van-nav-bar__arrow {
  font-size: 20px;
}

.PageBox {
  overflow: auto;
  padding-top: 0px;
  color: #333;
}


.van-pull-refresh {
  min-height: calc(100vh - 90px);
}

.activeTab {
  color:var(--tab-line-color);
}

.PageBox>>>.tool::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -80%;
}
.tool {

  /*background: url('../../../public/static/images/bg_wallet.png') no-repeat center 0;*/
  width: 95%;
  border-radius: 10px;
  /*background-color: rgba(244,211,79,0.4);*/
  height: 138px;
  overflow: hidden;
  z-index: 99;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 80px;
  padding: 20px;
  display: flex;
  color: var(--font_color);
  line-height: 35px;

  /* 设置圆角渐变 */
  border: 2px solid transparent;
  border-radius: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, var(--panel_color), var(--panel_color)), linear-gradient(to right,#f0cf03,#0099ff);
}
.van-cell__title {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.van-cell__title div {
  display: flex;
  flex-direction: column;
}
.van-cell__title div:nth-child(2) {
  align-items: flex-end;
}

.van-cell {
  margin-bottom: 10px !important;
  line-height: 24px !important;
}
.FundItem{
  //background-color: var(--panel_color3);
  margin: 0px;
  width: 100%;
  border-radius: 10px;
  line-height: 30px;
  margin-bottom: 5px;

  //width:355px;
  height:61px;
  border:1px solid #404040;
  border-radius:10px;
  opacity:1;

}

.van-tab__pane {
  width: 98%;
}

.PageBox /deep/ .van-tab__text {
  text-align: center;
  font-size: 18px;
}

.myhead {
  position: fixed;
  top: 0px;
  font-size: 18px;
  color: #000;
  background: #fff;
  width: 100%;
  height: 46px;
  line-height: 46px;
  text-align: center;
}

</style>
